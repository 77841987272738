import React from 'react';
import MaskedInput from 'antd-mask-input';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Empty, Table, Collapse, message, Upload, Tabs, Tooltip, Form, Input, Button, Divider, Row, Col, Radio, Space, Alert, Select, Modal, List, Avatar, Typography, Popconfirm, Checkbox } from 'antd';
import { SearchOutlined, FileDoneOutlined, UploadOutlined, InfoCircleOutlined, PlusOutlined, DeleteOutlined, EditOutlined, ToolOutlined, MenuOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;
const { Paragraph, Link, Text } = Typography;
const { TabPane } = Tabs;

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const Global = props => {

    const [docload, setDocload] = React.useState(0);
    const [sertsearch, setSertsearch] = React.useState('');

    const [formsearch] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [form5] = Form.useForm();
    const [form6] = Form.useForm();
    const [form7] = Form.useForm();
    const [form8] = Form.useForm();
    const [form9] = Form.useForm();


    formsearch.setFieldsValue({ search: sertsearch });

    var obj = {
        //* Поиск
        get sertlist () {
            if ( props.state.sert.length > 0 ) {
                let search = sertsearch.toLowerCase();
                return props.state.sert.filter( function( item ) {
                    return(
                        item['file'].toLowerCase().indexOf( search ) > -1 ||
                        item['text'].toLowerCase().indexOf( search ) > -1 ||
                        item['record'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.sert;
            }
        }
    }

    const columns = [
        {
            title: 'Файл',
            dataIndex: 'file',
            key: 'file',
            width: 100,
            render: (text) => <Link href={`${props.state.api}uploads/${text}`} target="_blank">{text}</Link>
        },
        {
            title: 'Название',
            key: 'text',
            render: (record) => 
                <Paragraph editable={{ tooltip: false, onChange: (e) => sert_upd({'method':'sert_upd','sertid':record.id,'text':e},{'method':'users_sert','doctor':props.state.usersitem.id}) }}>
                    {record.text}
                </Paragraph>,
            width: 100,
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','last'),
            width: 100,
            sorter: (a, b) => a.date.localeCompare(b.date),
        },{
            title: 'Действия',
            key: 'action',
            render: (record) => (
                <Space wrap key={record.id} size="middle">
                    <Popconfirm placement="topRight" onConfirm={() => sert_del({ 'method':'sert_del', 'sertid':`${record.id}` },{'method':'users_sert','doctor':props.state.usersitem.id})} title={`Вы действительно хотите удалить «${record.file}»?`} okText="Да" cancelText="Нет">
                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
            width: 100,
          }
    ];

    const SortableItemRew = SortableElement(({value, newindex}) => 
        <List.Item className="listnofull" actions={[
            <Tooltip title={`Редактировать`}>
                <Link key="a1" onClick={() => revpush(value)}><EditOutlined /></Link>
            </Tooltip>,
            <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'rev_del', 'revid':`${value.id}` },false)} 
                title={
                    <Space direction="vertical">
                        <Text>Вы действительно хотите удалить данный вопрос?</Text>
                        <Text>Все результаты, которые с ним связаны, также будут удалены.</Text>
                    </Space>
                } 
            okText="Да" cancelText="Нет">
                <Link key="a2" type="danger" ><DeleteOutlined /></Link>
            </Popconfirm>
        ]}>
            <List.Item.Meta
                title={<React.Fragment><Text strong>{newindex+1}.</Text> {value.text}</React.Fragment>} avatar={<DragHandle />}
            />
        
        </List.Item>
    );

    const SortableListRew = SortableContainer(({items}) => {
        return (
            <List
                className="listfull"
                itemLayout="horizontal"
            >
                {items.map((item, index) => (
                    <SortableItemRew key={`item-${item.id}`} index={index} newindex={index} value={item} />
                ))}
            </List>
        );
    });

    async function onSortEndRew ({oldIndex, newIndex}) {
        var reviews = props.state.reviews;
        reviews = arrayMove(props.state.reviews, oldIndex, newIndex);
        props.setState({
            reviews: reviews,
        });
        var v = {};
        v['method'] = 'rev_mv';
        for (var z = 0; z < reviews.length; z++) {
            v[`mv-${reviews[z]['id']}`] = z+1;
        }
        await props.api(v,false);
    };
    
    const SortableItemRew_users = SortableElement(({value, newindex}) => 
        <List.Item className="listnofull" actions={[
            <Tooltip title={`Редактировать`}>
                <Link key="a1" onClick={() => userspush(value)}><EditOutlined /></Link>
            </Tooltip>,
            <Tooltip title={`Обновить пароль`}>
                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'users_pas', 'usersid':`${value.id}` },false)} 
                    title={
                        <Space direction="vertical">
                            <Text>Вы действительно хотите обновить пароль сотрудника?</Text>
                        </Space>
                    } 
                okText="Да" cancelText="Нет">
                    <Link key="a2"><ToolOutlined /></Link>
                </Popconfirm>
            </Tooltip>,
            <Tooltip title={`Удалить`}>
                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'users_del', 'usersid':`${value.id}` },false)} 
                    title={
                        <Space direction="vertical">
                            <Text>Вы действительно хотите удалить данного сотрудника?</Text>
                        </Space>
                    } 
                okText="Да" cancelText="Нет">
                    <Link type="danger" key="a1"><DeleteOutlined /></Link>
                </Popconfirm>
            </Tooltip>
        ]}>
            <List.Item.Meta
                className="avatar_center"
                avatar={<React.Fragment><span style={{marginRight:'10px'}}><DragHandle /></span><Avatar src={value.ava !== '' ? `${props.state.api}uploads/${value.ava}` : ''} /></React.Fragment>}
                title={value.name}
                description={
                    <Space wrap direction="vertical">
                        <span>{value.role}</span>
                        {value.visible_lk === "1" ? 
                            <Text className="bold" type="success">Отображается в разделе врачей</Text>
                            :
                            <Text className="bold" type="danger">Не отображается в разделе врачей</Text>
                        }
                    </Space>
                }
            />
        </List.Item>
    );

    const SortableListRew_users = SortableContainer(({items}) => {
        return (
            <List
                className="listscroll"
                style={{maxHeight:500}}
                itemLayout="horizontal"
            >
                {items.map((item, index) => (
                    <SortableItemRew_users key={`item-${item.id}`} index={index} newindex={index} value={item} />
                ))}
            </List>
        );
    });

    async function onSortEndRew_users ({oldIndex, newIndex}) {
        var users = props.state.users;
        users = arrayMove(props.state.users, oldIndex, newIndex);
        props.setState({
            users: users,
        });
        var v = {};
        v['method'] = 'users_mv';
        for (var z = 0; z < users.length; z++) {
            v[`mv-${users[z]['id']}`] = z+1;
        }
        await props.api(v,false);
    };

    //* Текст поиска
    function onSearch (e) { 
        setSertsearch(e.target.value.replace(/\s+/g,' ')); 
    }

    async function sert_upd (v1,v2) {
        await props.api(v1,false);
        await props.api(v2,false);
    }

    async function sert_del (v1,v2) {
        await props.api(v1,false);
        await props.api(v2,false);
    }

    //@ Настройки
    //* Отправка формы настроек
    async function cladd(v) {
        v['method'] = 'settings_save';
        await props.api(v,false);
    }

    //@ Контакты
    //* Сохранение контактов
    async function contsave(v) {
        for( let value in v) {
            if(v[value] instanceof Array) {
               v[value] = v[value].join(',');
            }
        };
        v['method'] = 'cont_save';
        await props.api(v,false);
    }

    //@Платформы
    //* Вызов окна добавления платформы
    async function platpushadd() {
        await props.modal('plat_add');
    }

    //@ Профиль
    //* Сохранение пароля
    async function profilepass(v) {
        v['method'] = 'users_pasu';
        await props.api(v,false);
    }

    //@ SMS
    //* Сохранение данных SMS
    async function smssave(v) {
        v['method'] = 'sms_upd';
        await props.api(v,false);
    }

    //@ Вопросы
    //* Отправка формы вопроса
    async function platadd(v) {
        v['method'] = 'plat_add';
        await props.api(v,false);
    }

    //* Отправка формы вопроса
    async function revadd(v) {
        v['method'] = 'rev_add';
        var reviews = props.state.reviews;
        for (var z = 0; z < reviews.length; z++) {
            v[`mv-${reviews[z]['id']}`] = z+1;
        }
        await props.api(v,false);
    }

    //* Отправка формы вопроса
    async function revupd(v) {
        v['method'] = 'rev_upd';
        var reviews = props.state.reviews;
        for (var z = 0; z < reviews.length; z++) {
            v[`mv-${reviews[z]['id']}`] = z+1;
        }
        await props.api(v,false);
    }

    //* Добавление ответа в вопрос
    async function revplus() {
        let revitem= props.state.revitem;
        let id = 1;
        let text = 'Вариант';
        if(revitem.answers instanceof Array && revitem.answers.length > 0) {
            id = Number(revitem.answers[revitem.answers.length - 1]['id'])+1;
            await revitem.answers.push({
                id: id,
                text: text
            });
        } else {
            revitem.answers = [];
            await revitem.answers.push({
                id: id,
                text: text
            });
        }
        await props.setState( { revitem:revitem } );
        await form3.setFieldsValue({ [`rev-ans-${id}`]:text }); 
    }

    //* Удаление ответа из вопроса
    async function revdel(index) {
        let revitem = props.state.revitem;
        await revitem.answers.splice(index,1);
        await props.setState( { revitem:revitem } );
    }

    //* Изменение типа вопроса
    async function settype(v) {
        let revitem= props.state.revitem;
        revitem.type = v;
        await props.setState( { revitem:revitem } );
    }

    //* Вызов окна добавление вопроса
    async function revpushadd() {
        await form3.resetFields();
        await props.setState( { revitem:[] } );
        await props.modal('rev_add');
    }

    //* Вызов окна редактирования вопроса
    async function revpush(item) {
        await form3.resetFields();
        await props.setState( { revitem:item } );
        await form3.setFieldsValue({ revid:item.id, text:item.text, type:item.type }); 
        await props.modal('rev_upd');
        if (item.answers instanceof Array) {
            item.answers.map(list => {
                return form3.setFieldsValue({ [`rev-ans-${list.id}`]:list.text }); 
            });
        }
    }

    //* Отправка формы сотрудника
    async function persadd(v) {
        v['method'] = 'pers_add';
        await props.api(v,false);
    }

    //* Отправка формы сотрудника
    async function persupd(v) {
        v['method'] = 'pers_upd';
        await props.api(v,false);
    }

    //* Вызов окна добавление сотрудника
    async function perspushadd() {
        await form7.resetFields();
        await props.setState( { persitem:[] } );
        await props.modal('pers_add');
    }

    //* Вызов окна редактирования сотрудника
    async function perspush(item) {
        await form7.resetFields();
        await props.setState( { persitem:item } );
        await form7.setFieldsValue({ persid:item.id, name:item.name, type:item.type }); 
        await props.modal('pers_upd');
    }

    //* Отправка формы сотрудника
    async function usersadd(v) {
        v['method'] = 'users_add';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,false);
    }

    //* Отправка формы сотрудника
    async function usersupd(v) {
        v['method'] = 'users_upd';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,false);
    }

    //* Вызов окна добавление сотрудника
    async function userspushadd() {
        await form8.resetFields();
        await props.setState( { persitem:[] } );
        await props.modal('users_add');
    }

    //* Вызов окна редактирования сотрудника
    async function userspush(item) {
        await form8.resetFields();
        await props.setState( { usersitem:item } );
        await form8.setFieldsValue({ usersid:item.id, login:item.login, name:item.name, type:item.type, phone:item.phone, visible_lk:item.visible_lk, specialization:item.specialization, reception_time:item.reception_time, about:item.about }); 
        await props.modal('users_upd');
        await setTimeout(() => setDocload(1), 1000);
    }

    //* Конфиг загрузки
    const upload_config = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        action: props.state.api+'upload.php',
        headers: { authorization: 'token='+props.state.da.token },
        accept: 'image/jpeg,image/png'
    }

    const upload_config_multi = {
        name: 'file',
        multiple: true,
        action: props.state.api+'upload.php',
        headers: { authorization: 'token='+props.state.da.token },
        accept: 'image/jpeg,image/png'
    }

    //* Загрузка аватарки
    const upload_user_upload = {
        async onChange(info) {
            var error_text = '';
            if (info.file.status === 'removed') {
                console.log('Удаление',info.file,'Новый список',info.fileList);
            }
            if (info.file.status === 'uploading') {
                console.log('Загрузка',info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} файл загружен`);
                for(let a = 0; a < info.fileList.length; a++) {
                    console.log('Загружено',info.fileList[a]['response']);
                }
                await props.api({ 'method':'settings' },false).then(async json => {
                    if (json.code === '1') {
                        var usersearch = json.users.find(el => el.id == props.state.usersitem.id);
                        if (usersearch) {
                            props.setState( { usersitem:usersearch } );
                        }
                    }
                });
            } else if (info.file.status === 'error') {
                console.log('Файл не загружен',info.file,'Новый список',info.fileList);
                if (typeof info.file.response['error'] !== "undefined" ) {
                    error_text = info.file.response.error[1];
                }
                message.error(`${info.file.name} файл не загружен. ${error_text}`);
            }
        }
    };

    //* Загрузка примеров
    const upload_user_sert = {
        onChange(info) {
            var error_text = '';
            if (info.file.status === 'removed') {
                console.log('Удаление',info.file,'Новый список',info.fileList);
            }
            if (info.file.status === 'uploading') {
                console.log('Загрузка',info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} файл загружен`);
                for(let a = 0; a < info.fileList.length; a++) {
                    console.log('Загружено',info.fileList[a]['response']);
                }
                props.api({'method':'users_sert','doctor':props.state.usersitem.id},false);
            } else if (info.file.status === 'error') {
                console.log('Файл не загружен',info.file,'Новый список',info.fileList);
                if (typeof info.file.response['error'] !== "undefined" ) {
                    error_text = info.file.response.error[1];
                }
                message.error(`${info.file.name} файл не загружен. ${error_text}`);
            }
        }
    };

    return (
        <React.Fragment>
            <Modal className="modal-mini bg_chester" key="m1" title="Список переменных" visible={props.state.modal === 'variable'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Список переменных</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <List
                            itemLayout="horizontal"
                            dataSource={props.state.sms[1]}
                            renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                title={`{${item.name}}`}
                                description={item.text}
                                />
                            </List.Item>
                            )}
                        />
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m2" title="Добавление платформы" visible={props.state.modal === 'plat_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление платформы</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form4} onFinish={platadd} className="form" layout="vertical">
                            <Form.Item name={`name`} label='Название' rules={[{ required: true, message: `Пожалуйста, введите название!` }]}>
                                <Input suffix={<Tooltip title={`Отображаемое название`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`url`} label='Ссылка' rules={[{ required: true, message: `Пожалуйста, введите ссылку на платформу!` }]}>
                                <Input suffix={<Tooltip title={`Ссылка на платформу`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m3" title="Добавление вопроса" visible={props.state.modal === 'rev_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление вопроса</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form3} onFinish={revadd} className="form" layout="vertical">
                            <Form.Item name={`text`} label='Вопрос' rules={[{ required: true, message: `Пожалуйста, введите вопрос!` }]}>
                                <Input suffix={<Tooltip title={`Текст вопроса`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`type`} label='Тип' rules={[{ required: true, message: `Пожалуйста, выберите тип вопроса!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Свой ответ</Option>
                                    <Option value="2">Один вариант ответа</Option>
                                    <Option value="3">Несколько вариантов ответа</Option>
                                    <Option value="4">Рейтинг</Option>
                                </Select>
                            </Form.Item>
                            {props.state.revitem.type === '1' &&
                                <React.Fragment>
                                    <Alert type="info" showIcon={false} message="Вопрос со своим вариантом ответа" banner />
                                    {
                                        //@ Ничего не показываем
                                    }
                                </React.Fragment>
                            }
                            { props.state.revitem.type === '2' &&
                                <Alert type="info" showIcon={false} message="Вопрос с одним вариантом ответа" banner />
                            }
                            { props.state.revitem.type === '3' &&
                                <Alert type="info" showIcon={false} message="Вопрос с несколькими вариантами ответа" banner />
                            }
                            {(props.state.revitem.type === '2' || props.state.revitem.type === '3') &&
                                <React.Fragment>
                                    <Divider key="d1" orientation="left">Ответы</Divider>
                                    {
                                        props.state.revitem.answers instanceof Array &&
                                            <React.Fragment>
                                                {
                                                    props.state.revitem.answers.map( (list, index) => 
                                                        <Form.Item name={`rev-ans-${list.id}`} rules={[{ required: true, message: `Пожалуйста, введите ответ!` }]}>
                                                            <Input suffix={
                                                                <Tooltip title={`Удалить`}>
                                                                    <Link type="danger" onClick={() => revdel(index)} key="a1"><DeleteOutlined /></Link>
                                                                </Tooltip>
                                                            }
                                                                />
                                                        </Form.Item>
                                                    )
                                                }
                                            </React.Fragment>
                                        }
                                        <div className="fcenter">
                                            <Button type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => revplus()} />
                                        </div>
                                </React.Fragment>
                            }
                            { props.state.revitem.type === '4' &&
                                <Alert type="info" showIcon={false} message="Вопрос с выбором оценки" banner />
                            }
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m4" title="Редактирование вопроса" visible={props.state.modal === 'rev_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Редактирование вопроса</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form3} onFinish={revupd} className="form" layout="vertical">
                            <Form.Item name={`text`} label='Вопрос' rules={[{ required: true, message: `Пожалуйста, введите вопрос!` }]}>
                                <Input suffix={<Tooltip title={`Текст вопроса`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item hidden name={`revid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                <Input suffix={<Tooltip title={`ID`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            {props.state.revitem.type === '1' &&
                                <React.Fragment>
                                    <Alert type="info" showIcon={false} message="Вопрос со своим вариантом ответа" banner />
                                    {
                                        //@ Ничего не показываем
                                    }
                                </React.Fragment>
                            }
                            { props.state.revitem.type === '2' &&
                                <Alert type="info" showIcon={false} message="Вопрос с одним вариантом ответа" banner />
                            }
                            { props.state.revitem.type === '3' &&
                                <Alert type="info" showIcon={false} message="Вопрос с несколькими вариантами ответа" banner />
                            }
                            {(props.state.revitem.type === '2' || props.state.revitem.type === '3') &&
                                <React.Fragment>
                                    <Divider key="d1" orientation="left">Ответы</Divider>
                                    {
                                        props.state.revitem.answers instanceof Array &&
                                            <React.Fragment>
                                                {
                                                    props.state.revitem.answers.map( (list, index) => 
                                                        <Form.Item name={`rev-ans-${list.id}`} rules={[{ required: true, message: `Пожалуйста, введите ответ!` }]}>
                                                            <Input suffix={
                                                                <Tooltip title={`Удалить`}>
                                                                    <Link type="danger" onClick={() => revdel(index)} key="a1"><DeleteOutlined /></Link>
                                                                </Tooltip>
                                                            }
                                                                />
                                                        </Form.Item>
                                                    )
                                                }
                                            </React.Fragment>
                                        }
                                        <div className="fcenter">
                                            <Button type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => revplus()} />
                                        </div>
                                </React.Fragment>
                            }
                            { props.state.revitem.type === '4' &&
                                <Alert type="info" showIcon={false} message="Вопрос с выбором оценки" banner />
                            }
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                            
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m5" title="Добавление сотрудника" visible={props.state.modal === 'pers_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление сотрудника</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form7} onFinish={persadd} className="form" layout="vertical">
                            <Form.Item name={`name`} label='ФИО' rules={[{ required: true, message: `Пожалуйста, введите ФИО сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`ФИО сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`type`} label='Должность' rules={[{ required: true, message: `Пожалуйста, выберите должность сотрудника!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Администратор</Option>
                                    <Option value="2">Доктор</Option>
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m6" title="Редактирование сотрудника" visible={props.state.modal === 'pers_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Редактирование сотрудника</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form7} onFinish={persupd} className="form" layout="vertical">
                            <Form.Item name={`name`} label='ФИО' rules={[{ required: true, message: `Пожалуйста, введите ФИО сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`ФИО сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item hidden name={`persid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                <Input suffix={<Tooltip title={`ID`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`type`} label='Должность' rules={[{ required: true, message: `Пожалуйста, выберите должность сотрудника!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Администратор</Option>
                                    <Option value="2">Доктор</Option>
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m7" title="Добавление сотрудника" visible={props.state.modal === 'users_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление сотрудника</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form8} onFinish={usersadd} className="form" layout="vertical">
                            <Text type="warning">Логин может содержать латиницу и цифры. Длина логина может быть от 5 до 20 символов.</Text>
                            <Form.Item name={`login`} label='Логин' rules={[{ required: true, message: `Пожалуйста, придумайте логин сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`Логин сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`name`} label='ФИО' rules={[{ required: true, message: `Пожалуйста, введите ФИО сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`ФИО сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`phone`} label='Телефон' rules={[{ required: true, message: `Пожалуйста, введите номер телефона сотрудника!` }]}>
                                <MaskedInput
                                    prefix="+"
                                    mask="7 111-111-11-11"
                                    placeholder="7 999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name={`type`} label='Должность' rules={[{ required: true, message: `Пожалуйста, выберите должность сотрудника!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Руководитель</Option>
                                    <Option value="2">Администратор</Option>
                                    <Option value="3">Доктор</Option>
                                    <Option value="4">Ассистент врача</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="specialization" label="Специализация" >
                                <TextArea showCount maxLength={1000} rows={4} />
                            </Form.Item>
                            <Form.Item name="reception_time" label="Время приёма" >
                                <TextArea showCount maxLength={1000} rows={4} />
                            </Form.Item>
                            <Form.Item name="about" label="О специалисте" >
                                <TextArea showCount maxLength={1000} rows={4} />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal mask={false} className="modal-mini bg_chester" key="7" zIndex="1001" width={1200} title="Сертификаты" mask={false} visible={props.state.panel.indexOf( 'users_sert' ) != -1 } onOk={() => props.panel('users_sert')} onCancel={() => props.panel('users_sert')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Сертификаты специалиста</div>
                </div>
                <div className="modal-mini-body">
                    {'id' in props.state.usersitem ? (
                        <React.Fragment>
                            <Space wrap style={{width:'100%',justifyContent:'left'}}>
                                <Form style={{maxWidth:'210px'}} name="form" form={formsearch} >
                                    <Form.Item key="search" name="search" style={{marginBottom:0}}>
                                        <Input placeholder="Введите название" prefix={<SearchOutlined />} onChange={ onSearch } className="button_radius" />
                                    </Form.Item>
                                </Form>
                            </Space>
                            {docload ?
                                <Dragger 
                                    {...upload_config_multi}
                                    {...upload_user_sert}
                                    data={{'type':'user_certificate','doctor':props.state.usersitem.id}}
                                    className="upload_custom" 
                                    style={{borderRadius:'15px',margin:'10px 0'}}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Нажмите или перенесите файлы для загрузки</p>
                                </Dragger>
                            :
                                <React.Fragment>
                                    <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                </React.Fragment>
                            }
                            {!props.state.moreload ?
                                <React.Fragment>
                                    <Table 
                                        className="table-padding table-white"
                                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                                        dataSource={obj.sertlist} 
                                        scroll={{ x: 400 }} 
                                        pagination={{ position: ['none', 'bottomLeft'] }}
                                        rowKey="table1" 
                                        onChange={props.htc}
                                        columns={columns}
                                    >
                                    </Table>
                                </React.Fragment>
                            :
                                <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                            }
                        </React.Fragment>
                    ) : (
                        <Empty description="Информация о специалисте недоступна" />
                    ) }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m8" title="Редактирование сотрудника" visible={props.state.modal === 'users_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Редактирование сотрудника</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form8} onFinish={usersupd} className="form" layout="vertical">
                            <div class="profile_avatar">
                                {
                                    props.state.usersitem.ava !== "" &&
                                    <img src={`${props.state.api}uploads/${props.state.usersitem.ava}`} />
                                }
                            </div>
                            {props.state.usersitem.photo ?
                                <Link href={props.state.usersitem.photo}>Фотография</Link>
                                :
                                null
                            }
                            <Tabs type="card" className="column900 wowload custom_tabs">
                                <TabPane tab="Профиль" key="1" >
                                    <Form.Item name={`login`} label='Логин' rules={[{ required: true, message: `Пожалуйста, придумайте логин сотрудника!` }]}>
                                        <Input disabled suffix={<Tooltip title={`Логин сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                                    </Form.Item>
                                    <Form.Item name={`name`} label='ФИО' rules={[{ required: true, message: `Пожалуйста, введите ФИО сотрудника!` }]}>
                                        <Input suffix={<Tooltip title={`ФИО сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                                    </Form.Item>
                                    <Form.Item name={`phone`} label='Телефон' rules={[{ required: true, message: `Пожалуйста, введите номер телефона сотрудника!` }]}>
                                        <MaskedInput
                                            prefix="+"
                                            mask="7 111-111-11-11"
                                            placeholder="7 999-999-99-99"
                                            size="11"
                                            formatCharacters={{
                                            'W': {
                                                validate(char) { return /\w/.test(char ) },
                                                transform(char) { return char.toUpperCase() }
                                            }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item hidden name={`usersid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input suffix={<Tooltip title={`ID`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                                    </Form.Item>
                                    <Form.Item name={`type`} label='Должность' rules={[{ required: true, message: `Пожалуйста, выберите должность сотрудника!` }]}>
                                        <Select onChange={settype}>
                                            <Option value="1">Руководитель</Option>
                                            <Option value="2">Администратор</Option>
                                            <Option value="3">Доктор</Option>
                                            <Option value="4">Ассистент врача</Option>
                                        </Select>
                                    </Form.Item>
                                </TabPane>
                                <TabPane tab="Инфо" key="2" >
                                    <Form.Item name="specialization" label="Специализация" >
                                        <TextArea showCount maxLength={1000} rows={4} />
                                    </Form.Item>
                                    <Form.Item name="reception_time" label="Время приёма" >
                                        <TextArea showCount maxLength={1000} rows={4} />
                                    </Form.Item>
                                    <Form.Item name="about" label="О специалисте" >
                                        <TextArea showCount maxLength={1000} rows={4} />
                                    </Form.Item>
                                    <Form.Item name={`visible_lk`} label='Отображать в разделе врачей' rules={[{ required: true, message: `Пожалуйста, выберите один из вариантов!` }]}>
                                        <Select>
                                            <Option value="1">Да</Option>
                                            <Option value="0">Нет</Option>
                                        </Select>
                                    </Form.Item>
                                </TabPane>
                                <TabPane tab="Медиа" key="3" >
                                    {docload ?
                                        <React.Fragment>
                                            <div class="ant-col ant-form-item-label"><label title="Маленькая фотография">Маленькая фотография</label></div>
                                            <div className='dragger_block'>
                                                <Dragger 
                                                    {...upload_config}
                                                    {...upload_user_upload} 
                                                    data={{'type':'user_ava','user':props.state.usersitem.id}}
                                                    className="upload_custom" 
                                                    style={{borderRadius:'15px',margin:'0 0 10px 0'}}
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <UploadOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Нажмите или перенесите изображение для загрузки</p>
                                                </Dragger>
                                            </div>
                                            <div style={{marginTop:10}} class="ant-col ant-form-item-label"><label title="Большая фотография">Большая фотография</label></div>
                                            <div className='dragger_block'>
                                                <Dragger 
                                                    {...upload_config}
                                                    {...upload_user_upload} 
                                                    data={{'type':'user_photo','user':props.state.usersitem.id}}
                                                    className="upload_custom" 
                                                    style={{borderRadius:'15px',margin:'0 0 10px 0'}}
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <UploadOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Нажмите или перенесите изображение для загрузки</p>
                                                </Dragger>
                                            </div>
                                            <div className="fcenter">
                                                <Button className="des-button" type="dashed" shape="round" onClick={() => {props.panel('users_sert');setTimeout(() => setDocload(1), 1000);props.api({'method':'users_sert','doctor':props.state.usersitem.id},false)}} icon={<FileDoneOutlined />} >Сертификаты</Button>
                                            </div>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                        </React.Fragment>
                                    }
                                </TabPane>
                            </Tabs>
                            <div className="fcenter" style={{marginTop:20}}>
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Tabs type="card" className="column900 wowload custom_tabs">
                <TabPane tab="Профиль" key="1" >
                    <Row style={{flexDirection:'column'}} className="column900 wowload">
                        <Col key="k0_profile" className="shadowscreen" >
                            <div className="header_title">Учётная запись</div>
                            <div style={{padding:20}}>
                                <Form name="form" form={form9} onFinish={profilepass} className="form">
                                    <Form.Item name='pass' label="Пароль">
                                        <Input.Password />
                                    </Form.Item>
                                    <Divider />
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                { (props.state.da.type === "1" || props.state.da.type === "4" || props.state.da.type === "999") && 
                    <TabPane tab="Отзывы" key="2" >
                        <Row style={{flexDirection:'column'}} className="column900 wowload">
                            {!Number(props.state.balance) &&
                                <Col style={{padding:20}}>
                                    <div className="fcenter">
                                        <Alert
                                            style={{maxWidth:400}}
                                            banner
                                            showIcon={false}
                                            message={
                                            <React.Fragment>
                                                Внимание! Данные от SMS сервиса указаны неверно, либо баланс равен 0.
                                            </React.Fragment>
                                            }
                                        />
                                    </div>
                                </Col>
                            }
                            <Col key="k1_actplatform" className="shadowscreen" >
                                <div className="header_title">Активная платформа</div>
                                <div style={{padding:20}} >
                                    { form2.setFieldsValue({ active:props.state.settings[1] })}
                                    {
                                            props.state.settings[0].map(list => {
                                                return form2.setFieldsValue({ [`platform-${list.id}`]:list.url }); 
                                            })
                                    }
                                    <Form name="form" form={form2} onFinish={cladd} className="form">
                                        {(props.state.settings[1] === '' && props.state.settings[0].length > 0) &&
                                            <Alert
                                                style={{marginBottom:20}}
                                                message="Внимание!"
                                                description="У вас не выбрана активная платформа для отзывов, пожалуйста исправьте это."
                                                type="warning"
                                            />
                                        }
                                        <Form.Item name='active'>
                                            <Radio.Group>
                                                <Space direction="horizontal" wrap>
                                                    { props.state.settings[0].map( (item, index) => 
                                                        <Radio key={index} disabled={Number(item.block)} value={item.id}>{item.name}</Radio>
                                                    ) }
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Divider key="d2" orientation="left">Ссылки на платформы</Divider>
                                        { props.state.settings[0].map( (item, index) => 
                                            <Form.Item key={index} name={`platform-${item.id}`} label={item.name} rules={[{ required: true, message: `Пожалуйста, укажите ссылку на ${item.name}!` }]}>
                                                <Input suffix={
                                                    <Tooltip title={`Удалить`}>
                                                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'plat_del', 'platid':`${item.id}` },false)} 
                                                            title={
                                                                <Space direction="vertical">
                                                                    <Text>Вы действительно хотите удалить данную платформу?</Text>
                                                                </Space>
                                                            } 
                                                        okText="Да" cancelText="Нет">
                                                            <Link key="a2" type="danger"><DeleteOutlined /></Link>
                                                        </Popconfirm>
                                                    </Tooltip>
                                                } />
                                            </Form.Item>
                                        ) }
                                        <div className="fcenter">
                                            <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => platpushadd()} />
                                        </div>
                                        <Divider />
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                            <Col key="k2_reviews" className="shadowscreen" >
                                <div className="header_title">Список вопросов</div>
                                <div style={{padding:20}} >
                                    <SortableListRew items={props.state.reviews} onSortEnd={onSortEndRew} useDragHandle axis="y" lockAxis="y"  />
                                    <div className="fcenter">
                                        <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => revpushadd()} />
                                    </div>
                                </div>
                            </Col>
                            { (props.state.da.type === "1" || props.state.da.type === "999") && 
                                <Col key="k3_contadmin" className="shadowscreen" >
                                    <div className="header_title">Рассылка администрации</div>
                                    <div style={{padding:20}} >
                                        { form5.setFieldsValue({ conta:props.state.contacts[1] }) }
                                        {
                                                props.state.contacts[0].map(list => {
                                                    return form5.setFieldsValue({ [`cont-${list.id}`]:list.text }); 
                                                })
                                        }
                                        <Form name="form" form={form5} onFinish={contsave} className="form">
                                                <Form.Item key="conta" name="conta">
                                                    <Checkbox.Group>
                                                        <Space direction="vertical">
                                                            { props.state.contacts[0].map( (item, index) => 
                                                                <Checkbox value={item.id} >{item.name}</Checkbox>
                                                            ) }
                                                        </Space>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            <Divider key="d2" orientation="left">Параметры</Divider>
                                            { props.state.contacts[0].map( (item, index) => 
                                                <Form.Item key={index} name={`cont-${item.id}`} label={item.name}>
                                                    <Input />
                                                </Form.Item>
                                            ) }
                                            <Divider />
                                            <div className="fcenter">
                                                <Form.Item>
                                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            }
                            <Col key="k4_smssettings" className="shadowscreen" >
                                <div className="header_title">Настройка SMS сервиса</div>
                                <div style={{padding:20}} >
                                    {
                                        props.state.sms[0].map(list => {
                                            return form6.setFieldsValue({ [`sms-${list.name}`]:list.text }); 
                                        })
                                    }
                                    <Form name="form" form={form6} onFinish={smssave} className="form">
                                        {
                                            props.state.sms[0].map( (list, index) => 
                                                <React.Fragment>
                                                    {list.type === 'input' &&
                                                        <Form.Item name={`sms-${list.name}`} label={list.comment}>
                                                            <Input />
                                                        </Form.Item>
                                                    }
                                                    {list.type === 'textarea' &&
                                                        <Form.Item name={`sms-${list.name}`} label={list.comment}>
                                                            <TextArea showCount maxLength={150} />
                                                        </Form.Item>
                                                    }
                                                    {list.type === 'password' &&
                                                        <Form.Item name={`sms-${list.name}`} label={list.comment}>
                                                            <Input.Password />
                                                        </Form.Item>
                                                    }
                                                </React.Fragment>
                                            )
                                        }
                                        <Space direction="vertical">
                                            <Text><Link onClick={() => props.modal('variable')}>Список переменных</Link>, которые можно использовать в сообщениях.</Text>
                                        </Space>
                                        <Divider />
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                }
                { (props.state.da.type === "1" || props.state.da.type === "999") && 
                    <TabPane tab="Сотрудники" key="3" >
                        <Row style={{flexDirection:'column'}} className="column900 wowload">
                            <Col key="k4_emplist" className="shadowscreen" >
                                <div className="header_title">Сотрудники для опросов</div>
                                <div style={{padding:20}} >
                                    <List
                                        className="listscroll_script"
                                        itemLayout="horizontal"
                                        dataSource={props.state.pers}
                                        renderItem={item => (
                                        <List.Item actions={[
                                            <Tooltip title={`Редактировать`}>
                                                <Link key="a1" onClick={() => perspush(item)}><EditOutlined /></Link>
                                            </Tooltip>,
                                            <Tooltip title={`Удалить`}>
                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'pers_del', 'persid':`${item.id}` },false)} 
                                                    title={
                                                        <Space direction="vertical">
                                                            <Text>Вы действительно хотите удалить данного сотрудника?</Text>
                                                            <Text>Все результаты, которые с ним связаны, также будут удалены.</Text>
                                                        </Space>
                                                    } 
                                                okText="Да" cancelText="Нет">
                                                    <Link type="danger" key="a1"><DeleteOutlined /></Link>
                                                </Popconfirm>
                                            </Tooltip>
                                        ]}>
                                            <List.Item.Meta
                                            title={item.name}
                                            description={item.role}
                                            />
                                        </List.Item>
                                        )}
                                    />
                                    <div className="fcenter">
                                        <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => perspushadd()} />
                                    </div>
                                </div>
                            </Col>
                            <Col key="k5_userlist" className="shadowscreen" >
                                <div className="header_title">Учетные записи сотрудников</div>
                                <div style={{padding:20}} >
                                    <SortableListRew_users items={props.state.users} onSortEnd={onSortEndRew_users} useDragHandle axis="y" lockAxis="y"  />
                                    <div className="fcenter">
                                        <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => userspushadd()} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                }
            </Tabs>
        </React.Fragment>
    )
};

export default Global;