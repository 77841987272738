import React, { useState, useRef } from 'react';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import ReactToPrint   from 'react-to-print';
import { Form, Row, Col, Typography, Empty, DatePicker, Space, Button, List, Modal, Tooltip } from 'antd';
import { SearchOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons';

import Bg from '../../img/bgprint.svg';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

moment.locale('ru');

const Global = props => {

    const [list_full, setList_full] = useState([]);
    const [active_rev, setActive_rev] = useState('0');
    const [active_full, setActive_full] = useState(0);
    const [load_print, setLoad_print] = useState(0);
    const [class_print, setClass_print] = useState('');
    const [form1] = Form.useForm();

    const componentRef = useRef();

    //* Выбор даты
    async function dateChange(date, dateString) {
        await props.setState( { stats:[] } );
        await props.api({
            'method':'stats',
            'date_start':moment(dateString[0]).unix(),
            'date_end':moment(dateString[1]).unix()
        },false);
    }

    //* Выбор даты из предложенных
    async function datego(v) {
        await props.setState( { stats:[] } );
        await props.api({
            'method':'stats',
            'date':v
        },false);
    }

    return (
        <React.Fragment>
            {form1.setFieldsValue({ search: JSON.stringify(props.state.stats, null, 4) })}
            <Modal key="m1" title="Просмотр ответов" visible={props.state.modal === 'list_full'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <Title className="gray" level={5}>{list_full.name}</Title>
                {!props.state.moreload || !props.state.loading ?
                    <List
                        locale={{ emptyText: 
                            <Empty image={<SearchOutlined style={{fontSize:40,color:'#c5c5c5'}} />}
                                imageStyle={{
                                height: 60,
                                }} description='За данный период информация отсутствует' 
                            />
                        }}
                        pagination={false}
                        itemLayout="horizontal"
                        dataSource={list_full.data}
                        renderItem={itemlist => (
                        <List.Item>
                            <List.Item.Meta
                            title={<Text>» {itemlist['text']}</Text>}
                            description={<Text>Автор: {itemlist['client']['name']}</Text>}
                            />
                        </List.Item>
                        )}
                    />
                :
                    <div className="spincenter"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                }
            </Modal>
            <Row style={{flexDirection:'column'}} className="
            ">
                <Col key="filter">
                    <Row className="wrow">
                        <Col className="wcol shadowscreen" >
                            <Space className="nomargin" wrap={true} size={12}>
                                <Button type="dashed" shape="round" onClick={() => datego('today')}>Сегодня</Button>
                                <Button type="dashed" shape="round" onClick={() => datego('week')}>Неделя</Button>
                                <Button type="dashed" shape="round" onClick={() => datego('month ')}>Месяц</Button>
                                <RangePicker onChange={dateChange} format="YYYY-MM-DD" />
                                {active_full ? 
                                    <Button type="dashed" shape="round" onClick={() => setActive_full(0)}>Скрыть графики</Button>
                                    :
                                    <Button type="dashed" shape="round" onClick={() => setActive_full(1)}>Показать графики</Button>
                                }
                                <ReactToPrint
                                    trigger={() => <Button type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                    content={() => componentRef.current}
                                    onBeforeGetContent={() => 
                                        {
                                            return new Promise(async(resolve, reject) => {
                                                await setClass_print('class_print')
                                                await setLoad_print(1);
                                                await setTimeout(async function () {
                                                await setLoad_print(0);
                                                await setTimeout(async function () {
                                                    await resolve();
                                                    }.bind(this), 1000);
                                                }.bind(this), 1000);
                                            })
                                        }
                                    }
                                    onAfterPrint={() => 
                                        {
                                            return new Promise(async(resolve, reject) => {
                                                await setClass_print('')
                                                await setLoad_print(1);
                                                await setTimeout(async function () {
                                                await setLoad_print(0);
                                                await setTimeout(async function () {
                                                    await resolve();
                                                    }.bind(this), 1000);
                                                }.bind(this), 1000);
                                            })
                                        }
                                    }
                                />
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <div className="print" ref={componentRef}>
                    {(!props.state.moreload && !load_print) ? (
                        <React.Fragment>
                            {props.state.stats.length > 0 &&
                                props.state.stats.map( (list, index) => 
                                    <Col key={index}>
                                        <div className="fcenter">
                                            <Title className="gray" style={{marginTop:30}} level={4}>{list['name']}</Title>
                                        </div>
                                        {list['data'].length > 0 ? (
                                            <Row className="wrow">
                                                { list['data'].map( (item, index2) => 
                                                    <Col key={`r${index2}`} className="wcol shadowscreen" >
                                                        <Title className="gray" level={5}>{item['name']}</Title>
                                                        {
                                                            (active_rev === item.id || active_full === 1 || index < 2) ? (
                                                                <div className={`wowload ${class_print}`}>
                                                                    {item.count > 0 ? (
                                                                        <React.Fragment>
                                                                            {
                                                                                item['type'] === 'list' ? (
                                                                                    <React.Fragment>
                                                                                        {item.data instanceof Array ? (
                                                                                            <React.Fragment>
                                                                                                <List
                                                                                                    locale={{ emptyText: 
                                                                                                        <Empty image={<SearchOutlined style={{fontSize:40,color:'#c5c5c5'}} />}
                                                                                                            imageStyle={{
                                                                                                            height: 60,
                                                                                                            }} description='За данный период информация отсутствует' 
                                                                                                        />
                                                                                                    }}
                                                                                                    pagination={{ pageSize: 3 }}
                                                                                                    itemLayout="horizontal"
                                                                                                    dataSource={item.data}
                                                                                                    renderItem={itemlist => (
                                                                                                    <List.Item>
                                                                                                        <List.Item.Meta
                                                                                                        title={<Text>» {itemlist['text']}</Text>}
                                                                                                        description={<Text>Автор: {itemlist['client']['name']}</Text>}
                                                                                                        />
                                                                                                    </List.Item>
                                                                                                    )}
                                                                                                />
                                                                                                <div className="fcenter" style={{marginTop:20}}>
                                                                                                    <Button type="dashed" shape="round" onClick={() => {props.modal('list_full');setList_full(item)}}>Посмотреть все</Button>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <Empty image={<SearchOutlined  style={{fontSize:40,color:'#c5c5c5'}} />}
                                                                                                imageStyle={{
                                                                                                height: 60,
                                                                                                }} description='За данный период информация отсутствует' 
                                                                                            />
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        {(Object.keys(item['series']).length > 0 && Object.keys(item['options']).length)
                                                                                            ? (
                                                                                                <ReactApexChart type={item['type']} series={item['series']} options={item['options']} width={props.state.width} height={350} />
                                                                                            ) : (
                                                                                                <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                                                                            )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                            
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <Empty image={<SearchOutlined  style={{fontSize:40,color:'#c5c5c5'}} />}
                                                                            imageStyle={{
                                                                            height: 60,
                                                                            }} description='За данный период информация отсутствует' 
                                                                        />
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div className="revfull" onMouseEnter={() => setActive_rev(item.id)}>
                                                                    <Tooltip title={`Информация`}>
                                                                        <EyeOutlined style={{fontSize:64,color:'#e5e5e5'}} onClick={() => setActive_rev(item.id)} />
                                                                    </Tooltip>
                                                                </div>
                                                            )
                                                        }
                                                    </Col>
                                                ) }
                                            </Row>
                                        ) : (
                                            <Empty image={<SearchOutlined  style={{fontSize:40,color:'#c5c5c5'}} />}
                                            imageStyle={{
                                            height: 60,
                                            }} description='За данный период информация отсутствует' />
                                        )}
                                    </Col>
                                )
                            }
                        </React.Fragment>
                    ) : (
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    )}
                </div>
            </Row>
        </React.Fragment>
        
    )
};

export default Global;